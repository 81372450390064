<template>
  <b-modal
    id="member-game-txns-modal"
    ref="member-game-modal"
    title="ประวัติการเล่น"
    no-close-on-backdrop
    hide-footer
    @show="setData"
    size="xl"
    dialog-class="items-modal position-relative"
    body-class="p-0"
    header-class="align-items-center p-2"
  >
    <TxnsTable
      :items="items"
      :summary="summary"
      :totalItems="totalItems"
      :perPage="perPage"
      :currentPage="currentPage"
      @togglePage="togglePage"
    />

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="80"
      :width="80"
      color="#E5881E"
      loader="dots"
    />
  </b-modal>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import TxnsTable from './TxnsTable'

export default {
  name: 'ViewMemberGameModal',
  props: ['isShow', 'filters'],
  components: {
    TxnsTable
  },
  data() {
    return {
      isLoading: false,
      data: null,
      perPage: 100,
      currentPage: 1
    }
  },
  computed: {
    items() {
      return this.data?.items ?? []
    },
    totalItems() {
      return this.data?.summary?.count ?? 0
    },
    offset() {
      return (this.currentPage * this.perPage) - this.perPage
    },
    summary() {
      return this.data?.summary ?? {
        betAmount: 0,
        comAmount: 0,
        creditAmount: 0,
        payoutAmount: 0,
        sumAmount: 0,
        count: 0
      }
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.$refs['member-game-modal'].show()
      }else{
        this.$refs['member-game-modal'].hide()
      }
    }
  },
  methods: {
    loadItems() {
      this.isLoading = true
      GameService.getMemberHistory(this.filters, this.perPage, this.offset)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        this.$refs['member-game-modal'].hide()
        cAlert({
          title: 'ผิดพลาด!',
          text: 'ดึงรายการไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    setData() {
      this.currentPage = 1
      this.loadItems()
    },
    togglePage(page) {
      this.currentPage = page
      this.loadItems()
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss">
#member-game-txns-modal {
  .txns-table {
    .table {
      thead {
        tr {
          th {
            text-align: center;
            font-weight: 500;
            line-height: 1;
            font-size: 85%;
          }

          th:first-child, td:first-child {
            border-left: 0;
          }

          th:last-child, td:last-child {
            border-right: 0;
          }
        }
      }
      tbody {
        background-color: #FFF;
        tr {
          td {
            padding: 5px;
            vertical-align: middle;
            font-size: 85%;
          }
          th {
            padding: 5px;
            font-size: 85%;
          }

          th:first-child, td:first-child {
            border-left: 0;
          }

          th:last-child, td:last-child {
            border-right: 0;
          }
        }
      }
      tfoot {
        tr {
          td {
            font-size: 85%;
          }

          th:first-child, td:first-child {
            border-left: 0;
          }

          th:last-child, td:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}
</style>
